import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Elements" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="row resume">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <h4>Current</h4>
                <p>
                  <a href="https://generalassemb.ly/">General Assembly</a>
                  <br></br>
                  <small>
                    Instructional Associate,<br></br>
                    <em>Software Engineering Remote</em>
                  </small>
                </p>
                <p>
                  <a href="/">Major Danger Productions</a>
                  <br></br>
                  <small>Design, Brand &amp; Strategy</small>
                </p>
                <h4>Previous</h4>
                <p>
                  <a href="https://freerange.com/">Free Range</a>
                  <br></br>
                  <small>Production Manager</small>
                </p>
                <p>
                  Radium
                  <br></br>
                  <small>Bidding Producer</small>
                </p>
                <p>
                  Titan Media<br></br>
                  <small>Studio Coordinator</small>
                </p>
                <p>
                  The Orphanage<br></br>
                  <small>VFX Coordinator</small>
                </p>
                <p></p>
                <p>
                  Hoytyboy Pictures<br></br>
                  <small>Art Department Assistant</small>
                </p>
                <p>
                  Quest Productions<br></br>
                  <small>Assistant Producer</small>
                </p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <h4>Select Projects</h4>
                <p>
                  <a href="https://github.com/wdixon2186/City-Search-Front-End">
                    City Fyndr<br></br>
                  </a>
                  <small>Front End Engineer</small>
                </p>
                <p>
                  <a href="https://sm-art.herokuapp.com/tutorials">
                    smART<br></br>
                  </a>
                  <small>Full Stack Engineer</small>
                </p>
                <p>
                  <a href="https://github.com/jaredsmorgan/astronomy-memory-game">
                    Out of this World Memory<br></br>
                  </a>
                  <small>Front End Engineer</small>
                </p>
                <p>
                  <a href="https://www.myzenhen.com/">
                    Zen Hen<br></br>
                  </a>
                  <small>Creative &amp; Product Manager</small>
                </p>
                <p>
                  <a href="https://www.pandonutrition.com/">
                    Pando Nutrition<br></br>
                  </a>
                  <small>Brand Strategy &amp; Design</small>
                </p>
                <p>
                  <a href="https://youtu.be/M-8SJG8xny0">
                    Marin Clean Energy<br></br>
                  </a>
                  <small>Producer</small>
                </p>
                <p>
                  <a href="https://www.youtube.com/playlist?list=PL720Kw_OojlJMho0p2z43G0zYm2VCiBWU">
                    FEMA "Scary Simple"<br></br>
                  </a>
                  <small>Producer</small>
                </p>
                <p>
                  <a href="https://www.nyas.org/">
                    NYAS<br></br>
                  </a>
                  <small>Content Production Manager</small>
                </p>
                <p>
                  <a href="https://youtu.be/XbLSQZUjjig">
                    Dogwood Alliance<br></br> "Forest Fable"<br></br>
                  </a>
                  <small>Producer</small>
                </p>
                <p>
                  <a href="https://www.globalfundforwomen.org/">
                    Global Fund for Women<br></br>
                  </a>
                  <small>Production Manager</small>
                </p>
                <p>
                  <a href="https://vimeo.com/134804283">
                    Greenpeace "Mega Cats"<br></br>
                  </a>
                  <small>Producer</small>
                </p>
                <p>
                  <a href="https://www.immunityproject.org/">
                    Immunity Project<br></br>
                  </a>
                  <small>"World AIDS Conference" Presentation Design</small>
                </p>
                <p>
                  <a href="https://realvegancheese.org/">
                    Real Vegan Cheese<br></br>
                  </a>
                  <small>Brand Strategy &amp; Website Developer</small>
                </p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <h4>Awards</h4>
                <p>
                  The Rise &amp; Fall of Jim Crow<br></br>
                  <small>Peabody Award (2002)</small>
                </p>
                <h4>Skills</h4>
                <ul className="skills-list">
                  <li>React.js</li>
                  <li>PostgreSQL</li>
                  <li>Express.js</li>
                  <li>MongoDB</li>
                  <li>HTML5/CSS3</li>
                  <li>JavaScript</li>
                  <li>Node.js</li>
                  <li>Bootstrap</li>
                  <li>SASS</li>
                </ul>
              </div>
            </div>
          </div>
          <h4
            style={{
              padding: "0",
              margin: "0",
            }}
          >
            Select Clients
          </h4>
          <div className="row resume">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <p>Pando Nutrition</p>
                <p>Ad Council</p>
                <p>FEMA</p>
                <p>NHTSA</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <p>Global Fund for Women</p>
                <p>Greenpeace</p>
                <p>Lithium</p>
                <p>Shot@Life</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                }}
              >
                <p>United Nations Foundation</p>
                <p>SERVIR (NASA &amp; USAID)</p>
                <p>Audi</p>
                <p>Sprint</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    adCouncil: file(relativePath: { eq: "Ad_Council.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
